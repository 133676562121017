import React from 'react'
// import { Link } from "react-router-dom";
import { LuChevronRight } from "react-icons/lu";

const ArticleShowHeader = ({ article }) => {

    function splitSentence(sentence) {
        const words = sentence.split(' ');
        const almostAll = words.slice(0, -1).join(' ');
        const lastWord = words[words.length - 1];
        return [almostAll, lastWord];
    }

    function formatDate(dateString) {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0');
        const year = date.getFullYear();
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        return `${day}-${month}-${year} ${hours}:${minutes}`;
    }

    const [firstPart, secondPart] = splitSentence(article.title);
    const formattedDate = formatDate(article.created_at);
    return (
        <div className="relative flex flex-col items-center mt-6 lg:mt-10">
            <h4 className="text-white/50 text-xl sm:text-xl lg:text-xl text-center tracking-wide">
                Future Hotels
            </h4>
            <h1 className="py-4 lg:py-6 px-1 text-3xl sm:text-4xl lg:text-6xl text-center tracking-wide">
                {firstPart}
                <span className="bg-gradient-to-r from-indigo-500 to-indigo-900 text-transparent bg-clip-text">
                    {' '}
                    {secondPart}
                </span>
            </h1>
            <div className="">
                <div className="grid grid-cols-1 pb-8 text-center mt-6">
                    <ul className="list-none mt-6">
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Autor:</span> <span className="block">Justyna Panek</span></li>
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Data:</span> <span className="block">{formattedDate}</span></li>
                        <li className="inline-block text-white/50 mx-5"> <span className="text-white block">Przeczytasz w:</span> <span className="block">{article.read_time}</span></li>
                    </ul>
                </div>
            </div>

            <div className="text-center z-10 bottom-5 start-0 end-0 mx-3">
                <ul className="tracking-[0.5px] mb-0 inline-block">
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white/50 hover:text-white">
                        <a href="/blog" className="href">FutureHotels</a>
                    </li>
                    <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180"><LuChevronRight className="mdi mdi-chevron-right pt-1" /></li>
                    <li className="inline-block uppercase text-[13px] font-bold duration-500 ease-in-out text-white" aria-current="page">Artykuł</li>
                </ul>
            </div>
        </div>
    )
}

export default ArticleShowHeader
