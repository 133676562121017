import React from 'react';

const AIMessage = ({ message, resident }) => {
    return (
        <div className="flex items-start space-x-4">
            <div className="chat-image avatar">
                <div className="w-10 h-10 rounded-full overflow-hidden">
                    <img
                        alt="AI Bot avatar"
                        src={`../images/residents/${resident.image_filename}`}
                        className="object-cover"
                    />
                </div>
            </div>
            <div className="chat-bubble max-w-xl bg-neutral-800 text-neutral-400 p-3 rounded-md">
                {message.content}
            </div>
        </div>
    );
};

export default AIMessage;