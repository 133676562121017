import React from 'react';
import { Avatar } from '@nextui-org/react';

const UserMessage = ({ message }) => {
    return (
        <div className="flex items-end justify-end space-x-4">
            <div className="chat-bubble max-w-xl bg-neutral-800 text-neutral-400 p-3 rounded-lg">
                {message.content}
            </div>
            <div className="chat-image avatar">
                <div className="w-10 h-10 rounded-full">
                    <Avatar
                        squared
                        text="Joe" />
                </div>
            </div>
        </div>
    );
};

export default UserMessage;

