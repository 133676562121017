import React from 'react';

const UtlixContainer = ({ children }) => {
  return (
    <div className="container px-4 mx-auto relative text-sm">
      {children}
    </div>
  );
};

export default UtlixContainer
