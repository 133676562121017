import React from 'react';
import { createRoot } from 'react-dom/client';
import { NextUIProvider } from '@nextui-org/react';

import LandingPage from '../components/landing_page/LandingPage.jsx';
import ComingSoon from '../components/landing_page/ComingSoon.jsx';
import ArticlesIndex from '../components/articles/ArticlesIndex.jsx';
import ArticleShow from '../components/articles/ArticleShow.jsx';
import ResidentShow from '../components/residents/ResidentShow.jsx';

function renderComponent(elementId, Component, dataAttributes) {
    const dataElement = document.getElementById(elementId);
    if (dataElement) {
        const data = {};
        dataAttributes.forEach(attr => {
            const key = attr.replace(/-data$/, ''); // Remove '-data' suffix to use a clean key name
            data[key] = JSON.parse(dataElement.getAttribute(attr));
        });

        const root = createRoot(dataElement);
        root.render(
            <React.StrictMode>
                <NextUIProvider>
                    <Component {...data} />
                </NextUIProvider>
            </React.StrictMode>
        );
    }
}

renderComponent('react-root-aritcles', ArticlesIndex, ['articles-data']);
renderComponent('react-root-article', ArticleShow, ['article-data', 'relatedArticles-data']);
// renderComponent('react-root-landing-page', LandingPage, []);
renderComponent('react-root-landing-page', ComingSoon, []);
renderComponent('react-root-resident', ResidentShow, ['resident-data', 'discussion-data']);