import React from 'react'
import ArticleGridElement from './ArticleGridElement.jsx';


const ArticleRelated = ({ relatedArticles }) => {
    return (
        <div className="container lg:mt-24 mt-16">
            <div className="grid grid-cols-1 mb-6 text-center">
                <h3 className="font-semibold text-3xl leading-normal">Zobacz również</h3>
            </div>

            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
                {relatedArticles.map((article, index) => (
                    <ArticleGridElement key={index} index={index} {...article} />
                ))}
            </div>
        </div>
    )
}

export default ArticleRelated;
