import React, { useState } from 'react';

// import ChatComponent from '../chat/ChatComponent';
import UtlixContainer from '../../utils/UtlixContainer';
import { SparklesCore } from "../../utils/SparklesCore";

const ComingSoon = ({ }) => {

    return (
        <UtlixContainer>
            <div className="min-h-screen w-full flex flex-col items-center justify-center overflow-hidden rounded-md">
                <h4 className="mb-10 text-xl sm:text-xl lg:text-xl text-center tracking-wide">
                    Future Hotels</h4>
                <img src="images/future_hotels2.png" alt="" className="size-22"/>
                <h4 className="py-4 lg:py-6 px-1 text-xl sm:text-2xl md:text-3xl lg:text-6xl text-center tracking-wide">
                    Już wkrótce: Twój wirtualny<span className="bg-gradient-to-r from-indigo-500 to-indigo-900 text-transparent bg-clip-text animate-pulse">
                        {' '} rezydent!
                    </span>

                </h4>

                <h6 className="py-4 text-xs md:text-sm text-slate-400">
                    Spersonalizowani wirtualni rezydenci dla Twojego obiektu noclegowego.
                </h6>
                <div className="w-[40rem] h-40 relative">
                    {/* Gradients */}
                    <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-[2px] w-3/4 blur-sm" />
                    <div className="absolute inset-x-20 top-0 bg-gradient-to-r from-transparent via-indigo-500 to-transparent h-px w-3/4" />
                    <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-[5px] w-1/4 blur-sm" />
                    <div className="absolute inset-x-60 top-0 bg-gradient-to-r from-transparent via-sky-500 to-transparent h-px w-1/4" />

                    {/* Core component */}
                    <SparklesCore
                        background="transparent"
                        minSize={0.4}
                        maxSize={1}
                        particleDensity={100}
                        className="w-full h-full"
                        particleColor="#FFFFFF"
                    />

                    {/* Radial Gradient to prevent sharp edges */}
                    <div className="absolute inset-0 w-full h-full bg-neutral-900 [mask-image:radial-gradient(350px_140px_at_top,transparent_20%,white)]"></div>
                    {/* <h6 className="z-50 bottom-5 text-xs md:text-sm text-slate-400">
                        Odkrywaj, Planuj, Personalizuj – Twoje wakacje zaczynają się tutaj
                    </h6> */}
                </div>

            </div>

        </UtlixContainer>
    );
};

export default ComingSoon;