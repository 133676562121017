import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

import ArticleGridElement from './ArticleGridElement';

const ArticlesGrid = ({ articles }) => {
    const [displayedArticles, setArticles] = useState(articles);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(true);

    const renderArticles = () => {
        return displayedArticles.map((article, index) => (
            <ArticleGridElement key={index} index={index} {...article} />
        ));
    };

    const handleScroll = () => {
        if (loading || !hasMoreData) return;

        if (window.innerHeight + document.documentElement.scrollTop + 1 >= document.documentElement.scrollHeight) {
            fetchData();
        }
    };
    
    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading, hasMoreData]);
    
    const fetchData = async () => {
        try {
            setLoading(true);
            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf;

            const response = await axios.get(`/blog/infinite_load`, {
                params: { page }
            });

            const newData = response.data;

            if (newData.length > 0) {
                setArticles((prevData) => [...prevData, ...newData]);
                setPage((prevPage) => prevPage + 1);
            } else {
                setHasMoreData(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setError('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <section className="" id="articles_section">
            <div className="">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {renderArticles()}
                </div>
            </div>
        </section>
    )
}
export default ArticlesGrid;