import React from 'react';
import AIMessage from './AIMessage';
import UserMessage from './UserMessage';

const Messages = ({ messages, resident }) => {
    return (
        <div className="space-y-4  m-2">
            {
                messages.map((message, index) => (
                    message.sender === 'AI Bot' ? (
                        <AIMessage key={index} message={message} resident={resident} />
                    ) : (
                        <UserMessage key={index} message={message} />
                    )
                ))
            }
        </div>
    );
};

export default Messages;