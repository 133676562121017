import React, { useState } from 'react';

const ArticleImage = ({ children, ...props }) => {
    return (
        <div className="mb-4">
            <img {...props}>{children}</img>
        </div>
    );
};

export default ArticleImage;