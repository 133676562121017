import React, { useState } from 'react';
// import { Link } from "react-router-dom";
import { LuAlarmClock } from "react-icons/lu";
import { Skeleton } from "@nextui-org/skeleton";

const ArticleGridElement = ({ id, category_name, head_image, title, read_time, author, description, index, urlslug }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const link = `/blog/${urlslug}`;
    const onLoad = () => {
        setIsLoaded(true)
    };
    
    return (
    
            <div className="group relative overflow-hidden">
                <a href={link} className="href">
                    <Skeleton isLoaded={isLoaded} className="rounded-lg">
                        <div className="relative overflow-hidden rounded-md shadow dark:shadow-gray-800">
                            <img src={head_image} onLoad={onLoad} className="group-hover:scale-110 group-hover:rotate-3 duration-500" alt="" />
                            <div className="absolute top-0 start-0 p-4 opacity-0 group-hover:opacity-100 duration-500">
                                <span className="bg-red-500 text-white text-[12px] px-2.5 py-1 font-medium rounded-md h-5">{category_name}</span>
                            </div>
                        </div>
                    </Skeleton>
                </a>
                <div className="mt-6">
                    <Skeleton isLoaded={isLoaded} className="w-3/5 rounded-lg mb-2">
                        <div className="flex mb-4">
                            <span className="flex items-center text-slate-400 text-sm"><LuAlarmClock className="size-4 text-slate-900 dark:text-white me-1.5" />{read_time}</span>
                            <span className="text-slate-400 text-sm ms-1">by <a href="" className="text-slate-900 dark:text-white hover:text-indigo-500 dark:hover:text-indigo-500 font-medium duration-500 ease-in-out">Justyna Panek</a></span>
                        </div>
                    </Skeleton>
                    <a href={link} className="text-lg font-medium hover:text-indigo-500 duration-500 ease-in-out">{title}
                        <Skeleton isLoaded={isLoaded} className="w-full rounded-lg mb-2">
                            {/* <a href={link} className="text-lg font-medium hover:text-indigo-500 duration-500 ease-in-out">{title}</a> */}
                        </Skeleton>
                    
                    </a>
                    <Skeleton isLoaded={isLoaded} className="w-full rounded-lg">
                        <p className="text-slate-400 mt-2">{description}</p>
                    </Skeleton>
                    <div className="mt-3">
                        <Skeleton isLoaded={isLoaded} className="w-2/5 rounded-lg">
                            {/* <Link className="hover:text-indigo-500 inline-flex items-center duration-500 ease-in-out" to={link}>
                                Czytaj więcej... <i data-feather="chevron-right" className="size-4 ms-1"></i>
                            </Link> */}
                        </Skeleton>
                    </div>
                </div>

            </div>

    )
}

export default ArticleGridElement;
