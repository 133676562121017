import React, { useState } from 'react';
// import { useLoaderData } from "react-router-dom";
// import { ScrollRestoration } from "react-router-dom";

import UtlixContainer from '../../utils/UtlixContainer.jsx';
import ArticleShowHeader from './ArticleShowHeader.jsx';
import ArticleContent from './ArticleContent.jsx';
import ArticleRelated from './ArticleRelated.jsx';

export async function loader({ params }) {
    return params.articleId
}

const ArticleShow = ({ article, relatedArticles }) => {
    return (
        <UtlixContainer>
            <ArticleShowHeader article={article} />

            <section className="relative md:py-24 py-16">
                <ArticleContent article={article} />
                <ArticleRelated relatedArticles={relatedArticles} />
            </section>
            {/* <ScrollRestoration />     */}
        </UtlixContainer>
    );
};

export default ArticleShow;