import React, { useState, useEffect } from 'react';
import ChatWithResident from './ChatWithResident.jsx'

const ResidentShow = ({ resident }) => {
    
    return (
        <section className="m-2">
            <div className="flex flex-row">
                <div className="basis-1/12 lg:basis-3/12 text-center">01</div>
                <div className="basis-10/12 lg:basis-6/12"> 
                    <ChatWithResident resident={resident} />
                </div>
                <div className="basis-1/12 lg:basis-3/12 text-center">03</div>
            </div>
        </section>
    );
};

export default ResidentShow;