import React, { useState, useEffect } from 'react';
import Markdown from 'markdown-to-jsx';
import ArticleImage from './ArticleImage'

const ArticleMarkdownContent = ({ url }) => {
    const [content, setContent] = useState('');

    useEffect(() => {
        import(`../../markdown/${url}.md`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => setContent(res))
                    .catch(err => console.log(err));
            })
            .catch(err => console.log(err));
    });

    return (
        <Markdown options={{
            overrides: {
                h1: {
                    props: {
                        className: 'mt-8 mb-4 text-2xl leading-none tracking-tight text-gray-900 dark:text-white',
                    },
                },
                h2: {
                    props: {
                        className: 'mt-6 mb-6 text-xl leading-none tracking-tight text-gray-900 dark:text-white',
                    },
                },
                h3: {
                    props: {
                        className: 'mt-4 mb-4 text-lg leading-none tracking-tight text-gray-900 dark:text-white',
                    },
                },
                p: {
                    props: {
                        className: 'text-base mb-12 text-gray-400',
                    },
                },
                img: {
                    component: ArticleImage,
                    props: {
                        className: 'block mx-auto max-w-full rounded-md',
                    },
                },
                ul: {
                    props: {
                        className: 'max-w space-y-1 text-gray-500 list-inside dark:text-gray-400'
                     }
                }
            }
        }}>
            {content}
        </Markdown>
    )
}
export default ArticleMarkdownContent
