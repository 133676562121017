import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import Messages from './Messages.jsx';
import { Button, Textarea } from "@nextui-org/react";
import { LuSendHorizonal } from "react-icons/lu";

const ChatWithResident = ({ resident }) => {
    const [currentDiscussion, setCurrentDiscussion] = useState(null);
    const [inputMessage, setInputMessage] = useState('');
    const [messages, setMessages] = useState([]);
    const [sendButtonDisabled, setSendButtonDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const chatPanelRef = useRef(null); 

    console.log('resident')
    console.log(resident)

    // useEffect to scroll to the bottom after new message is added
    useEffect(() => {
        if (chatPanelRef.current) {
            const chatPanelElement = chatPanelRef.current;
            chatPanelElement.scrollTo(0, chatPanelElement.scrollHeight);
        }
    }, [messages]); 

    useEffect(() => {
        const savedDiscussionId = localStorage.getItem(`${resident.property_name}-discussion`);
        const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
        axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf
        axios.get(`/residents/${resident.id}/discussions`, {
            params: { discussion_id: savedDiscussionId}
        })
        .then(response => {
            const discussion = response.data.data.discussion;
            setCurrentDiscussion(discussion);
            setMessages(response.data.data.messages)
            localStorage.setItem(`${resident.property_name}-discussion`, discussion.id);
        })
        .catch(error => {
            console.error('Error fetching discussion:', error);
        });
        
    }, [resident.id, resident.property_name]);

    const handleSubmit = async () => {
        if (inputMessage.trim() !== '') {
            setLoading(true)

            const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content")
            axios.defaults.headers.common['X-CSRF-TOKEN'] = csrf
            let url = `/residents/${resident.id}/discussions/${currentDiscussion.id}/messages`;

            axios.post(url, {
                message: {
                    content: inputMessage
                }
            })
            .then(response => {
                console.log(response)
                if (response.data.error) {
                    console.log('Error saving new message');
                } else {
                    console.log('Message sent successfully!');
                    setInputMessage('');
                    console.log('response message:')
                    console.log(response.data)
                    setMessages((prevMessages) => prevMessages.concat(response.data.data.messages))
                    setLoading(false)
                }
            }).catch(error => {
                console.error('Error fetching data:', error);
            })
        }
    };

    const handleChange = (e) => {
        const newValue = e.target.value
        if (newValue.length == 0) {
            setSendButtonDisabled(true);
        } else {
            setSendButtonDisabled(false);
        }

        setInputMessage(e.target.value);
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            handleSubmit();
        }
    };

    return (
        <div className="flex flex-col justify-items-center h-screen min-h-screen">
            <div className='basis-3/12'>
                <img className="group-hover:border-indigo-800 
                m-auto left-0 right-0 top-7 overflow-visible mb-4 w-28 h-28 rounded-full 
                border-4 border-neutral-900" src={`../images/residents/${resident.image_filename}`} alt="Bonnie Avatar" />
                <div className="font-bold text-xl mb-2 text-center">
                    {`${resident.property_name}`}
                </div>
                <p className="text-gray-500 text-base text-center">
                    {resident.name}
                </p>
            </div>
            <div ref={chatPanelRef} className="basis-7/12 overflow-y-auto">
                <Messages messages={messages} resident={resident} />
            </div>
            <div className="basis-3/12 pt-10 align-middle">
                <div className="flex flex-row gap-x-2 justify-items-center overflow-auto">
                    <Textarea 
                        className='text-neutral-400'
                        minRows={1}
                        radius='sm' 
                        color="primary"
                        variant='bordered' 
                        placeholder={`Napisz wiadomość do ${resident.name}`} 
                        value={inputMessage}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown} 
                    />
                    <Button isIconOnly 
                        radius='sm' 
                        color="primary"
                        isDisabled={sendButtonDisabled}
                        isLoading={loading}
                        onClick={handleSubmit}>
                        <LuSendHorizonal />
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default ChatWithResident;